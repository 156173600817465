import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["body"]

  connect() {}

  updateModal(e) {
    const url = `/client/proposals/${e.currentTarget.dataset.proposalId}`
    fetch(url, {headers: {"Accept": "text/plain"}})
      .then(response => response.text())
      .then((data) => {
        this.bodyTarget.innerHTML = data
      })
  }
}
